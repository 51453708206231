import $ from 'jquery';
import Cookies from 'js-cookie';
require('normalize.css/normalize.css');
require('../css/main.scss');
import 'regenerator-runtime/runtime';
import { db, getDoc, doc, auth, registerUser, getCompanyByName, createCompany, serverTimestamp, onAuthStateChanged, signOutUser, addDoc, collection } from '../js/firebaseConfig';
import { checkAuthAndRedirect } from '../js/redirect.js';

$(document).ready(function() {
  /**********
   * 
   * 
   * 
   * 
   * DEMO ASSESSMENT 
   * 
   * 
   * 
   * 
   * **********/
  $('.trophy').on('click', function() {
    var $trophy = $(this);
    $trophy.addClass('vibrate');
    setTimeout(function() {
        $trophy.removeClass('vibrate');
    }, 300);
  });

  function formatTime(timestamp) {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }
  function formatDuration(durationString) {
    const durationMinutes = parseInt(durationString, 10);
    return durationMinutes;
  }
  window.addEventListener('message', async (event) => {
    if (event.origin === 'https://tally.so') {
      try {
        if (!event.data.startsWith('{')) {
          return;
        }
        const eventData = JSON.parse(event.data);
        if (eventData.event === 'Tally.FormSubmitted') {
          var formData = eventData.payload;
          var filteredData = formData.fields.filter(field => 
            field.title === 'Votre email professionnel' ||
            field.title === 'Nom de votre entreprise'
          );
          var filteredDataObject = {};
          filteredData.forEach(field => {
            if (field.title === 'Votre email professionnel') {
              filteredDataObject.email = field.answer.value;
            } else if (field.title === 'Nom de votre entreprise') {
              filteredDataObject.company = field.answer.value;
            }
          });
          Cookies.set('formData', JSON.stringify(filteredDataObject), {
            path: '/',
            sameSite: 'Lax',
            secure: false,
            expires: 1
          });
          onAuthStateChanged(auth, (user) => {
            window.location.href = 'https://www.salesridge.co/demo/assessment.html';
          });
        }
      } catch (error) {
        console.error('Erreur lors de l\'analyse des données de l\'événement:', error);
      }
    }
    if (event.origin === 'https://meetings-eu1.hubspot.com') {
      try {
        const eventData = event.data;
        if (eventData.meetingBookSucceeded) {
          const meetingsPayload = eventData.meetingsPayload;
          const bookingResponse = meetingsPayload.bookingResponse;
          const eventDetails = bookingResponse.event;
          const companyId = Cookies.get('companyId');
          async function addAppointment() {
            try {
              const appointmentRef = await addDoc(collection(db, 'appointments'), {
                companyId: companyId,
                date: eventDetails.dateString,
                duration: formatDuration(eventDetails.duration),
                time: formatTime(eventDetails.dateTime)
              });
              $('<div class="notificationsRDV"><p>Rendez-vous pris avec succès.</p></div>').prependTo('body .plan');
              $('body').removeClass('bookMeeting');
            } catch (e) {
              console.error("Error adding document: ", e);
            }
          }
          await addAppointment();
        }
      } catch (error) {
        console.error('Erreur lors de la conversion du message en JSON:', error);
      }
    }
  });
  $(document).on("click", "#startAssessment", function() {
    $('body').addClass('startAssessment');
  });
  //MOBILE
  $('#mobileMenu').on('click', function() {
    event.preventDefault();
    $('body').addClass('navOpen');
  });
  $('#navBackground').on('click', function() {
    event.preventDefault();
    $('body').removeClass('navOpen');
  });
  //HUBSPOT
  var script = document.createElement('script');
  script.type = 'text/javascript';
  script.id = 'hs-script-loader';
  script.async = true;
  script.defer = true;
  script.src = '//js.hs-scripts.com/26298630.js';
  $('body').append(script);
});